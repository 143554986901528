<template>
<v-container class="page_container">
 <projects project_block_title="Our Projects" post_limit="100" />
</v-container>
</template>
<style>
</style>
<script>
import projects from '@/components/projects.vue'
export default {
  components:{
      projects
  }
};
</script>
